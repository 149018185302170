body {
    margin: 0;
    font-family: 'Rubik', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #444444;
}

div#root {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

footer {
    background: transparent;
    color: #444;
    opacity: 0.5;
    text-align: center;
    padding: 0;
}

main {
    flex: 1 0 auto;
}

img {
    height: 50px;
}

main.body {
    margin-top: 112px;
}

@media only screen and (min-width: 1023px) {
    div#root,
    header#header {
        margin: 0 20% 0 20%;
        border-left: solid 1px #e0e0e0;
        border-right: solid 1px #e0e0e0;
    }

    header#header {
        width: 60%;
    }
}

th,
td {
    border: none;
}

table {
    width: 100%;
    display: table;
    border-collapse: collapse;
    border-spacing: 0;
}

table.striped tr {
    border-bottom: none;
}

table.striped > tbody > tr:nth-child(odd) {
    background-color: rgba(242, 242, 242, 0.5);
}

table.striped > tbody > tr > td {
    border-radius: 0;
}

table.highlight > tbody > tr {
    -webkit-transition: background-color 0.25s ease;
    transition: background-color 0.25s ease;
}

table.highlight > tbody > tr:hover {
    background-color: rgba(242, 242, 242, 0.5);
}

table.centered thead tr th,
table.centered tbody tr td {
    text-align: center;
}

table th.center-align,
table td.center-align {
    text-align: center;
}

table td > a,
table tr > a {
    text-decoration: none;
    color: inherit;
}

tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

td,
th {
    padding: 15px 5px;
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    border-radius: 2px;
}

@media only screen and (max-width: 992px) {
    table.responsive-table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        display: block;
        position: relative;
        /* sort out borders */
        border-top: solid 1px #ddd;
        border-bottom: solid 1px #ddd;
    }
    table.responsive-table td:empty:before {
        content: '\00a0';
    }
    table.responsive-table th,
    table.responsive-table td {
        margin: 0;
        vertical-align: top;
    }
    table.responsive-table th {
        text-align: left;
    }
    table.responsive-table thead {
        display: block;
        float: left;
    }
    table.responsive-table thead tr {
        display: block;
        padding: 0 10px 0 0;
    }
    table.responsive-table thead tr th::before {
        content: '\00a0';
    }
    table.responsive-table tbody {
        display: block;
        width: auto;
        position: relative;
        overflow-x: auto;
        white-space: nowrap;
    }
    table.responsive-table tbody tr {
        display: inline-block;
        vertical-align: top;
    }
    table.responsive-table th {
        display: block;
        text-align: right;
    }
    table.responsive-table td {
        display: block;
        min-height: 1.25em;
        text-align: left;
    }
    table.responsive-table tr {
        border-bottom: none;
        padding: 0 10px;
    }
    table.responsive-table thead {
        border: 0;
        border-right: 1px solid rgba(0, 0, 0, 0.12);
    }
}
