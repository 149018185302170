table.stats tr.sub td {
    text-align: left;
    font-style: italic;
    padding-left: 22px;
    font-weight: bold;
}

table.stats tr.sub td.win-loss {
    text-align: center;
}
