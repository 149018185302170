ul.form {
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: center;
}

ul.form li {
    color: white;
    opacity: 0.75;
    height: 26px;
    padding: 2px;
    list-style-type: none;
}

ul.form.afl li {
    height: 20px !important;
}

ul.form li:not(:first-child) {
    margin-left: 2px;
}

ul.form li.loser {
    background-color: #f44336;
}

ul.form li.winner {
    background-color: #2196f3;
}

ul.form li img {
    height: 24px;
}

ul.form li img.afl {
    height: 16px;
}
