.team i {
    font-size: 46px;
}

.team li:first-child {
    text-align: left;
}

.team li:last-child {
    text-align: right;
}
