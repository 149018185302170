.select {
    padding: 10px;
}

.chart {
    padding: 0 10px 0 10px;
}

div.strategy table.results td.win {
    color: green;
}

div.strategy table.results td.loss {
    color: #f44336;
}

div.strategy div.figures {
    display: flex;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
}

div.strategy h3.dollars,
div.strategy h3.percentage {
    width: 100px;
    text-align: center;
    font-size: 2rem;
    margin: 0 0 10px 0;
}

div.strategy h3.dollars::before {
    content: '$';
    font-size: 1.4rem;
    color: gray;
}

div.strategy h3.percentage::after {
    content: '%';
    font-size: 1.4rem;
    color: gray;
}

div.strategy h4.return {
    color: gray;
    font-size: 1.2rem;
    text-align: center;
    padding: 0;
    margin: 10px;
}

div.strategy table.results tr.pending {
    opacity: 0.5;
}
