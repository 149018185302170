div.loading {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
}

div.loading img {
    height: 140px;
}

div.loading > div {
    text-align: center;
}

div.loading > div > h1 {
    font-size: 2rem;
}
