span.bookmaker {
    display: inline-block;
    min-width: 32px;
    min-height: 32px;
}

table.odds > tbody > tr > td > span.bookmaker.beteasy {
    background-image: url(/content/bookmakers.png);
    background-position: -573px -41px;
    width: 40px;
    height: 40px;
}

table.odds > tbody > tr > td > span.bookmaker.betfair {
    background-image: url(/content/bookmakers.png);
    background-position: -573px -369px;
    width: 40px;
    height: 40px;
}

table.odds > tbody > tr > td > span.bookmaker.ladbrokes {
    background-image: url(/content/bookmakers.png);
    background-position: -451px -564px;
    width: 40px;
    height: 40px;
}

table.odds > tbody > tr > td > span.bookmaker.neds {
    background-image: url(/content/bookmakers.png);
    background-position: -573px -205px;
    width: 40px;
    height: 40px;
}

table.odds > tbody > tr > td > span.bookmaker.sportsbet {
    background-image: url(/content/bookmakers.png);
    background-position: -614px 0px;
    width: 40px;
    height: 40px;
}

table.odds > tbody > tr > td > span.bookmaker.tab {
    background-image: url(/content/bookmakers.png);
    background-position: -287px -564px;
    width: 40px;
    height: 40px;
}

table.odds > tbody > tr > td > span.bookmaker.topbetta {
    background-image: url(/content/bookmakers.png);
    background-position: -573px -451px;
    width: 40px;
    height: 40px;
}

table.odds > tbody > tr > td > span.bookmaker.unibet {
    background-image: url(/content/bookmakers.png);
    background-position: -573px -164px;
    width: 40px;
    height: 40px;
}

table.odds .totals,
table.odds .line {
    display: flex;
    justify-content: center;
}

table.odds .totals span,
table.odds .line span {
    margin: auto 0;
}
