.button {
    position: fixed;
    bottom: 10px;
    right: 10px;
    opacity: 0.65;
}

.form {
    position: fixed;
    bottom: 0;
    right: 0;
}

.form button {
    color: gray;
}

.form input[type='email'] {
    margin-top: 0;
    caret-color: #fff;
    color: #fff;
}

.form input[type='email']:focus {
    border-bottom: solid 1px #fff !important;
    box-shadow: none !important;
    caret-color: #fff;
    color: #fff;
}

@media only screen and (min-width: 1023px) {
    .form {
        position: fixed;
        bottom: 0;
        right: 20px;
    }
}
