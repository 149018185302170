img.logo {
    height: 50px;
}

img.logo.afl {
    height: 42px;
}

span.logo-position {
    position: relative;
}

span.logo-position > .pos-right {
    position: absolute;
    right: -36px;
}

span.logo-position > .pos-left {
    position: absolute;
    left: -36px;
}

span.logo-position > span {
    font-style: italic;
    font-size: 1.1rem;
}
